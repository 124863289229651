export async function SetUserName(uid, name, options) {
  let update = {};
  // Allow usergroup to be passed in by admins who do not have access to user_groups
  if (options?.rootCompany) {
    update[`users/${options.rootCompany}/${uid}/name`] = name;
    update[`photoheight/company_space/${options.rootCompany}/users/${uid}/name`] = name;
  } else {
    let userGroup = await FirebaseWorker.ref(`user_groups/${uid}`)
      .once('value')
      .then((s) => s.val());
    update[`users/${userGroup.root_company}/${uid}/name`] = name;
    for (let companyId in userGroup.individual_permissions) {
      update[`photoheight/company_space/${companyId}/users/${uid}/name`] = name;
    }
  }
  update[`user_info/${uid}/name`] = name;
  await FirebaseWorker.ref().update(update);
}
