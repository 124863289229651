import '@polymer/paper-styles/color.js';
import '@polymer/paper-styles/shadow.js';
import '@webcomponents/shadycss/entrypoints/apply-shim.js';
import { KatapultElement, html } from '../../mixins/katapult-element';

class PaperTableInputElement extends KatapultElement {
  ready() {
    super.ready();
    this.addEventListener('click', (e) => {
      if (this.hasAttribute('checkbox')) {
        if (e.currentTarget) {
          let checkbox = e.currentTarget.querySelector('paper-checkbox, paper-toggle-button, paper-radio-button');
          if (checkbox && checkbox != e.target && !checkbox.disabled) {
            // checkbox.getRipple().simulatedRipple();
            // checkbox.checked = !checkbox.checked;
            // Switched to calling click() method because toggling checked property didn't register as a user initiated event.
            checkbox.click();
          }
        }
      } else if (this.hasAttribute('input')) {
        if (e.currentTarget) {
          let input = e.currentTarget.querySelector('paper-input, katapult-drop-down, paper-textarea');
          if (input && input != e.target) input.focus();
        }
      }
    });
  }
}
class PaperCell extends PaperTableInputElement {
  static get template() {
    return html`
      <style>
        :host {
          display: flex;
          position: relative;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 100%;
          padding: 12px;
          box-sizing: border-box;
          flex-grow: 1;
          flex-basis: 0;
        }
        :host([shrink]),
        :host([fit-content]) {
          flex-grow: unset;
          flex-basis: auto;
        }
        :host([icon]),
        :host([icon-button]) {
          max-width: 48px;
          min-width: 48px;
          flex-shrink: 0;
          justify-content: center;
        }
        :host([icon-button]) {
          padding: 0;
        }
        :host([center='']) {
          justify-content: center;
        }
        :host([left='']) {
          justify-content: flex-start;
        }
        :host([right='']) {
          justify-content: flex-end;
        }
        :host([pointer]:hover) {
          cursor: pointer;
          @apply --pointer-hover;
        }
        :host([round]) {
          border-radius: 999px;
        }
        :host(:not([icon])) ::slotted(iron-icon:first-child:not(:last-child)) {
          margin-right: 8px;
          flex-shrink: 0;
        }
        :host([grow]) {
          flex-grow: 1;
        }
        ::slotted(paper-input),
        ::slotted(paper-textarea),
        ::slotted(katapult-drop-down) {
          flex-grow: 1;
        }
      </style>
      <template is="dom-if" if="[[ripple]]" restamp>
        <paper-ripple></paper-ripple>
      </template>
      <slot></slot>
    `;
  }

  static get is() {
    return 'paper-cell';
  }

  static get properties() {
    return {
      ripple: {
        type: Boolean,
        value: false
      }
    };
  }
}
window.customElements.define(PaperCell.is, PaperCell);
class PaperRow extends PaperTableInputElement {
  static get template() {
    return html`
      <style>
        :host {
          display: flex;
          position: relative;
          flex-shrink: 0;
          flex-direction: row;
          align-items: center;
          height: 48px;
          width: 100%;
          box-sizing: border-box;
          @apply (--paper-row-style);
        }
        :host([shrink]) {
          width: unset;
        }
        :host([tall]) {
          height: 56px;
        }
        :host([short]) {
          height: 36px;
        }
        :host([micro]) {
          height: 24px;
        }
        :host([expand]) {
          height: unset;
        }
        :host([header]) {
          text-transform: uppercase;
        }
        :host([secondary-color]) {
          background-color: var(--secondary-color);
          color: var(--secondary-color-text-color);
        }
        :host([pointer]:hover) {
          cursor: pointer;
          @apply --pointer-hover;
        }
      </style>
      <template is="dom-if" if="[[ripple]]" restamp>
        <paper-ripple></paper-ripple>
      </template>
      <slot></slot>
    `;
  }

  static get is() {
    return 'paper-row';
  }

  static get properties() {
    return {
      ripple: {
        type: Boolean,
        value: false
      }
    };
  }
}
window.customElements.define(PaperRow.is, PaperRow);
class PaperTable extends KatapultElement {
  static get template() {
    return html`
      <style>
        :host {
          display: flex;
          position: relative;
          flex-direction: column;
          font-size: 11pt;
          text-align: left;
        }
      </style>
      <slot></slot>
    `;
  }

  static get properties() {
    return {
      scrolled: {
        type: Boolean,
        value: false,
        notify: true,
        reflectToAttribute: true
      }
    };
  }

  static get is() {
    return 'paper-table';
  }
}
window.customElements.define(PaperTable.is, PaperTable);
class PaperTableScroll extends KatapultElement {
  static get template() {
    return html`
      <style>
        :host {
          position: relative;
          overflow-y: auto;
          overflow-x: hidden;
        }
        :host([animating]) ::slotted(*) {
          transition: transform 0.3s !important;
        }
        #sortableContainer {
          position: relative;
          width: inherit;
        }
        ::slotted(.dragging) {
          position: absolute;
          z-index: 999;
          @apply --shadow-elevation-4dp;
          @apply --paper-table-scroll-dragging-item;
        }
      </style>
      <slot name="before"></slot>
      <div id="sortableContainer">
        <slot id="sortableSlot" name="sortable" on-slotchange="scrollItemsChanged"></slot>
      </div>
      <slot name="after"></slot>
    `;
  }

  static get is() {
    return 'paper-table-scroll';
  }
  static get properties() {
    return {
      animating: {
        type: Boolean,
        value: false,
        notify: true,
        reflectToAttribute: true
      },
      disabled: {
        type: Boolean,
        value: false,
        reflectToAttribute: true
      },
      dragging: {
        type: Boolean,
        value: false,
        notify: true,
        reflectToAttribute: true
      },
      modifyDom: {
        type: Boolean,
        value: false
      },
      scrollTarget: {
        type: Element,
        value: null
      }
    };
  }
  ready() {
    super.ready();
    this.addEventListener('scroll', (e) => {
      let elem = this;
      while (elem.parentElement != null && elem.parentElement != window) {
        elem = elem.parentElement;
        if (elem.tagName === 'PAPER-TABLE') {
          elem.scrolled = this.scrollTop > 0;
          break;
        }
      }
    });
    this.addEventListener('mousedown', (e) => {
      this.mouseDownHandler(e);
    });
    this.addEventListener('touchstart', (e) => {
      e.stopPropagation();
      this.mouseDownHandler(e);
      this.dragStartHandler(e);
    });
    this.addEventListener('touchend', (e) => {
      // We do NOT stop propogation here because Polymer mobile on-click events use touchend
      // This may be buggy for nested sortable tables on mobile
      this.dragEndHandler(e);
    });
    this.addEventListener('mouseup', (e) => {
      this.mouseDownCleanup();
    });
    this.addEventListener('dragstart', (e) => {
      e.stopPropagation();
      this.dragStartHandler(e);
    });
    let scrollTarget = this.scrollTarget || this;
    scrollTarget.addEventListener(
      'scroll',
      (e) => {
        e.stopPropagation();
        // Calc scroll deltas.  They are used to maintain proper offset for drag item if list scrolled while dragging.
        this.scrollDeltaVert = scrollTarget.scrollTop - (this.grabScrollVert || 0);
        this.scrollDeltaHoriz = scrollTarget.scrollLeft - (this.grabScrollHoriz || 0);
        // Update position of drag item and surrounding items.
        if (this.dragging) this.dragUpdateItems();
      },
      { passive: true }
    );
    this.addEventListener(
      'drag',
      (e) => {
        e.stopPropagation();
        this.dragHandler(e);
      },
      { passive: true }
    );
    this.addEventListener(
      'touchmove',
      (e) => {
        e.stopPropagation();
        this.dragHandler(e);
      },
      { passive: true }
    );
    this.addEventListener('dragend', (e) => {
      e.stopPropagation();
      this.dragEndHandler(e);
    });
    this.addEventListener('dragover', (e) => {
      e.stopPropagation();
      e.preventDefault();
    });
    this.addEventListener('drop', (e) => {
      if (this.dragging) {
        e.stopPropagation();
        e.preventDefault();
        // Calc the index the drag item began at.
        let prevDragItemIndex = this.sortableItems.indexOf(this.dragItem);
        // If modifyDom, re-insert the item in the correct location.
        if (this.modifyDom) {
          this.insertBefore(this.dragItem, this.sortableItems[this.dragItemIndex + (prevDragItemIndex > this.dragItemIndex ? 0 : 1)]);
        }
        // Fire event detailing how the item has moved.
        let range = [prevDragItemIndex, this.dragItemIndex].sort();
        let shift = prevDragItemIndex < this.dragItemIndex ? -1 : 1;
        this.dispatchEvent(
          new CustomEvent('sort-changed', {
            detail: { range, shift, prevIndex: prevDragItemIndex, index: this.dragItemIndex, items: this.sortableItems }
          })
        );
      }
    });
  }
  mouseDownHandler(e) {
    this.canDrag = false;
    // Store reference to target so we can see if the drag originated from the handle.
    this.mouseDownTarget = e.target;
    this.mouseDownSortItem = this.getSortableItem({ event: e });
    if (this.mouseDownSortItem) {
      this.handle = this.mouseDownSortItem.querySelector('[drag-handle]');
      this.canDrag = !this.disabled && (!this.handle || this.handle.contains(this.mouseDownTarget));
      if (this.canDrag) this.mouseDownSortItem.setAttribute('draggable', true);
    }
  }
  async dragStartHandler(e) {
    if (this.canDrag) {
      // Clear Drag Image.
      if (e.dataTransfer) e.dataTransfer.setDragImage(new Image(), 0, 0);
      // Get item to drag from dragstart event.
      let item = this.getSortableItem({ event: e });
      // Get click/touch coords.
      let { clientX, clientY } = this.getEventCoords(e);
      // Calc offset between click point and drag item.
      this.grabOffsetX = this.getAbsolute(item, 'offsetLeft') - clientX;
      this.grabOffsetY = this.getAbsolute(item, 'offsetTop') - clientY;
      // Save scroll position at time of scroll.
      let scrollTarget = this.scrollTarget || this;
      this.grabScrollVert = scrollTarget.scrollTop;
      this.grabScrollHoriz = scrollTarget.scrollLeft;
      // Clear scroll deta.
      this.scrollDeltaVert = this.scrollDeltaHoriz = 0;
      // Wait 10ms before doing anything that manipulates the DOM (https://groups.google.com/a/chromium.org/g/chromium-bugs/c/YHs3orFC8Dc/m/ryT25b7J-NwJ?pli=1).
      if (e.type == 'dragStart') await new Promise((x) => setTimeout(x, 10));
      // Set dragging flag.
      this.dragging = true;
      // Set animating flag asyncronously so that initial position of items doesn't get animated.
      setTimeout(() => (this.animating = this.dragging));
      // Setup mutation observer to watch key attribute on drag item.
      this.dragItemObserver = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type == 'attributes' && mutation.attributeName == 'key') {
            // Stop animating.
            this.animating = false;
            // Stop dragging current item since it no longer has a matching key.
            this.stopDraggingItem();
            // Begin dragging item with matching key.
            this.startDraggingItem(this.getSortableItem({ key: this.dragKey }));
            // Reposition items.
            this.dragUpdateItems();
            // Start animating again.
            setTimeout(() => (this.animating = this.dragging));
          }
        });
      });
      // Begin dragging item.
      this.startDraggingItem(item);
      // Call dragUpdate to make sure that all elements are positioned correctly.
      this.dragUpdate(e);
      // Prevent default, unless the event is a touch event
    } else if (!e.type.startsWith('touch')) e.preventDefault();
  }
  dragEndHandler(e) {
    let { clientX, clientY } = this.getEventCoords(e);
    if (this.dragItem && clientY) {
      let parentRect = this.getBoundingClientRect();
      // If the mouse location is outside the top or bottom of the table, then
      // fire a custom drop event to drop the item at the top or bottom of the list
      // Always fire this event when the event is a touch event
      if (clientY < parentRect.top || clientY > parentRect.bottom || e.type.startsWith('touch')) {
        this.dispatchEvent(new CustomEvent('drop'));
      }
    }
    // Reset drag item.
    this.stopDraggingItem();
    // Destroy mutation observer.
    this.dragItemObserver = null;
    // Clear dragging and animating flags.
    this.dragging = this.animating = false;
    // Cleanup from mousedown.
    this.mouseDownCleanup();
  }
  dragHandler(e) {
    if (!this.updatingFrame) {
      this.updatingFrame = true;
      window.requestAnimationFrame(() => {
        if (e.type == 'drag') e.dataTransfer.dropEffect = 'move';
        this.dragUpdate(e);
        this.updatingFrame = false;
      });
    }
  }
  getEventCoords(e) {
    let clientX, clientY;
    if (e.type.startsWith('touch')) {
      if (e.type == 'touchend') {
        clientX = e.changedTouches[0].clientX;
        clientY = e.changedTouches[0].clientY;
      } else {
        clientX = e.touches[0].clientX;
        clientY = e.touches[0].clientY;
      }
    } else {
      clientX = e.clientX;
      clientY = e.clientY;
    }
    return { clientX, clientY };
  }
  stopDraggingItem() {
    if (this.dragItem) {
      // Disconnect mutation observer.
      this.dragItemObserver.disconnect();
      // Reset item style.
      for (let prop in this.dragItemStyle) {
        if (!isNaN(prop)) continue;
        this.dragItem.style[prop] = this.dragItemStyle[prop];
      }
      // Remove dragging class.
      this.dragItem.classList.remove('dragging');
      // Clear references to drag item.
      this.dragItem = this.dragItemStyle = this.dragItemHeight = this.sortableContainerScrollHeight = null;
      // Clear all transforms on list elements.
      this.sortableItems.forEach((item) => (item.style.transform = ''));
      // Remove list container placeholder padding.
      this.$.sortableContainer.style.paddingBottom = '';
    }
  }
  startDraggingItem(item) {
    if (item) {
      // Store reference to dom element being dragged.
      this.dragItem = item;
      // Look for a key attribute to watch on the element.
      this.dragKey = item.getAttribute('key');
      // If it has a key, setup mutation observer to watch for key change.  When key changes, look for another sortable item that has the saved key and drag it instead.
      if (this.dragKey) this.dragItemObserver.observe(item, { attributes: true });
      // Save item style to reset style later.
      this.dragItemStyle = JSON.parse(JSON.stringify(item.style));
      // Store height of drag item.
      this.dragItemHeight = item.offsetHeight;
      // Store scroll height of the sortable container when dragging starts.
      this.sortableContainerScrollHeight = this.$.sortableContainer.scrollHeight;
      // Set explicit width of drag item (about to be absolutely positioned and lose and parent inherited width).
      item.style.width = item.offsetWidth + 'px';
      // Set explicit height of drag item (see above).
      item.style.height = this.dragItemHeight + 'px';
      // Store position and height of sortable container at drag start.
      this.sortableContainerX = this.getAbsolute(this.$.sortableContainer, 'offsetLeft');
      this.sortableContainerY = this.getAbsolute(this.$.sortableContainer, 'offsetTop');
      // Set sortableContainer padding bottom to height of drag item to compensate for item being removed.
      this.$.sortableContainer.style.paddingBottom = this.dragItemHeight + 'px';
      // Finally, add dragging class to item.
      item.classList.add('dragging');
    }
  }
  dragUpdate(e) {
    let { clientX, clientY } = this.getEventCoords(e);

    // Check if the clientX and clientY are not 0 (meaning they are on screen)
    // If you move off of the screen, they will go to 0 and the calculation for
    // clientY will be messed up. So just don't update if they are 0

    if (clientX && clientY) {
      // Set Local Coordinates.
      this.dragLocalX = clientX - this.sortableContainerX;
      this.dragLocalY = clientY - this.sortableContainerY;
      // Update position of drag item and surrounding items.
      this.dragUpdateItems();
    }
  }
  getAbsolute(element, property) {
    let temp = element[property];
    while (element.offsetParent) temp += (element = element.offsetParent)[property];
    return temp;
  }
  getSortableItem(options) {
    options = options || {};
    if (options.event) {
      /* Changed method of finding sortable item */
      if (this.sortableItems) return this.sortableItems.find((x) => x.contains(options.event.target));
      // let target = options.event.target;
      // while (target.slot != 'sortable' && target.parentElement) target = target.parentElement;
      // return (target.slot == 'sortable' ? target : null);
    } else if (options.key) return this.sortableItems.find((item) => item.getAttribute('key') == options.key);
  }
  mouseDownCleanup() {
    if (this.mouseDownSortItem) {
      this.mouseDownSortItem.removeAttribute('draggable');
      this.mouseDownSortItem = this.handle = null;
      this.canDrag = false;
    }
  }
  scrollItemsChanged(e) {
    this.sortableItems = this.$.sortableSlot.assignedNodes();
  }
  dragUpdateItems() {
    // Calc Drag Item Position.
    let dragTargetPosX = this.dragLocalX + this.grabOffsetX + this.scrollDeltaHoriz;
    let dragTargetPosY = this.dragLocalY + this.grabOffsetY + this.scrollDeltaVert;
    // this.dragItemX = this.offsetLeft; // Removed because we don't want the absolute position x of the item to be the offsetLeft of the paper-scroll.
    this.dragItemX = 0;
    if (dragTargetPosY < 0) this.dragItemY = 0;
    else if (dragTargetPosY + this.dragItemHeight > this.sortableContainerScrollHeight)
      this.dragItemY = this.sortableContainerScrollHeight - this.dragItemHeight;
    else this.dragItemY = dragTargetPosY;
    if (this.dragItem) {
      // Move the drag item to the position specified by dragItemX and dragItemY.
      this.dragItem.style.top = this.dragItemY + 'px';
      this.dragItem.style.left = this.dragItemX + 'px';
    }
    // Count the number of items above the dragging item (same as index).
    let numItemsAbove = 0;
    this.sortableItems.forEach((item) => {
      if (item != this.dragItem) {
        let stickyFactor = (item.style.transform ? 1 : -1) * 4; // An offset based upon state which resists change.  Keeps an item from rapidly switching order as the drag item is moved back and forth across a midpoint.
        if (item.offsetTop + item.offsetHeight / 2 + stickyFactor > this.dragItemY) {
          item.style.transform = `translate(0px, ${this.dragItemHeight}px)`;
        } else {
          numItemsAbove++;
          item.style.transform = '';
        }
      }
    });
    this.dragItemIndex = numItemsAbove;
  }
}
window.customElements.define(PaperTableScroll.is, PaperTableScroll);
