/**
 * @module CallOnRequestFirebaseFunction
 * @remarks The purpose of this module is to easily call any onRequest Firebase Function on any project.
 */

/**
 * Calls a Google Cloud Function.
 * @param {string} functionName - The name of the function to call.
 * @param {string} projectId - The ID of the project that the function is in.
 * @param {object} [data] - The data to send to the function.
 * @returns {Promise<Response>} The response from the function.
 */
export async function CallOnRequestFirebaseFunction(functionName, projectId, data = {}) {
  let url = `https://us-central1-${projectId}.cloudfunctions.net/${functionName}`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...data })
    });
    if (!response.ok) {
      throw new Error((await response.json()).message);
    }
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
}
