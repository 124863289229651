/**
 * Provides functions related to server identity.
 */

/** @typedef {import('firebase/compat').default.database.Database} Database */
/** todo this isn't right @typedef {import('firebase/compat/app') | import('firebase-admin/app').Auth} FirebaseApp */

import { HashEmail } from './HashEmail.js';
import { isFlagEnabled } from './FeatureFlags.js';
import { CallCrossServerIdentityAction } from './CallCrossServerIdentityAction.js';

/**
 * Gets the ID of the home server for the provided company
 * @param {Database} database - The database to use for the request
 * @param {string} companyId - ID of the company to get the home server for
 * @returns {Promise<string|null>} ID of the company's home server, or null if the company does not exist or the home server is not set
 */
export async function getHomeServerIdForCompany(database, companyId) {
  const companyHomeServerPath = `photoheight/company_space/${companyId}/home_server`;
  const companyHomeServerId = (await database.ref(companyHomeServerPath).once('value'))?.val();

  return companyHomeServerId;
}

/**
 * Determines whether the provided server is the home server of the provided company
 * @param {Database} database - The database to use for the request
 * @param {string} companyId - ID of the company to check
 * @param {string} serverId - ID of the server to check
 * @returns {Promise<boolean>} Whether the provided server is the home server of the provided company
 */
export async function checkIsHomeServerForCompany(database, companyId, serverId) {
  const companyHomeServerId = await getHomeServerIdForCompany(database, companyId);

  return companyHomeServerId === serverId;
}

/**
 * Determines whether the provided database is the home server of the provided user
 * @param {Database} database - The database to use for the request
 * @param {string} userId - ID of the user to check
 * @param {string} serverId - ID of the server to check
 * @returns {Promise<boolean>} Whether the provided server is the home server of the provided user
 */
export async function checkIsHomeServerForUser(database, userId, serverId) {
  const rootCompanyPath = `user_groups/${userId}/root_company`;
  const rootCompany = (await database.ref(rootCompanyPath).once('value'))?.val();

  return checkIsHomeServerForCompany(database, rootCompany, serverId);
}

/**
 * Checks if a user is licensed.
 * @param {Object} database - The database to use for the request.
 * @param {string} companyId - The ID of the company.
 * @param {string} userId - The ID of the user.
 * @returns {Promise<boolean>} Whether the user is licensed or not.
 */
export async function checkIsUserLicensed(database, companyId, userId) {
  const userLicensedPath = `users/${companyId}/${userId}/licensed`;
  return (await database.ref(userLicensedPath).once('value'))?.val() || false;
}

/**
 * Checks whether the provided company id is in use on any Katapult Pro server.
 * @param {FirebaseApp} firebaseApp - The firebase client (compat) sdk or the firebase admin sdk to use for the request
 * @param {string} companyId - ID of the company to check (this should be a company name already formatted with NameToKey)
 * @param {import('./CallCrossServerIdentityAction.js').AuthenticationOptions} [authenticationOptions] - The authentication options for the requests
 * @returns {Promise<boolean>} Whether the company id is in use on any server
 */
export async function checkIfCompanyIdInUse(firebaseApp, companyId, authenticationOptions) {
  // TODO (2024-08-08): Remove this yucky code
  if ((await _checkCrossServerIdentity(firebaseApp.database())) !== true) {
    const companyIdInUseOnThisServer = (await firebaseApp.database().ref(`photoheight/company_space/${companyId}`).once('value'))?.exists();

    return companyIdInUseOnThisServer;
  }

  const companyIdInUse = await (
    await CallCrossServerIdentityAction(
      firebaseApp.auth(),
      'COMPANY_RECORD_EXISTS',
      {
        serverId: globalThis.config.appName ?? globalThis.config.projectId,
        companyId
      },
      authenticationOptions
    )
  ).json();
  return companyIdInUse === true;
}

/**
 * Checks whether the provided email address is in use on any Katapult Pro server.
 * @param {FirebaseApp} firebaseApp - The firebase client (compat) sdk or the firebase admin sdk to use for the request
 * @param {string} email - Email address of the user to check
 * @param {import('./CallCrossServerIdentityAction.js').AuthenticationOptions} authenticationOptions - The authentication options for the requests
 * @returns {Promise<boolean>} Whether the email address is in use on any server
 */
export async function checkIfEmailInUse(firebaseApp, email, authenticationOptions) {
  // TODO (2024-08-08): Remove this yucky code
  if ((await _checkCrossServerIdentity(firebaseApp.database())) !== true) {
    const hashedEmail = await HashEmail.hash(email);
    const emailInUseOnThisServer = (await firebaseApp.database().ref(`photoheight/userHashLookup/${hashedEmail}`).once('value'))?.exists();

    return emailInUseOnThisServer;
  }

  const emailInUse = await (
    await CallCrossServerIdentityAction(
      firebaseApp.auth(),
      'USER_RECORD_EXISTS',
      {
        serverId: globalThis.config.appName ?? globalThis.config.projectId,
        userEmail: email
      },
      authenticationOptions
    )
  ).json();
  return emailInUse === true;
}

/**
 * Temporary helper function: checks if this server has the flag enabled to limit admin actions to the home server
 * @param {Database} database - The database to use for the request
 * @returns {Promise<boolean>} Whether the flag is enabled
 */
export async function _checkLimitAdminActionsToHomeServer(database) {
  return await isFlagEnabled('limit_admin_actions_to_home_server', database, true);
}

/**
 * Temporary helper function: checks if this server has the flag enabled to perform cross server identity actions
 * @param {Database} database - The database to use for the request
 * @returns {Promise<boolean>} Whether the flag is enabled
 */
export async function _checkCrossServerIdentity(database) {
  return await isFlagEnabled('cross_server_identity', database, true);
}
