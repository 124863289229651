/**
 * @module CallCrossServerIdentityAction
 */
import { CallOnRequestFirebaseFunction } from './CallOnRequestFirebaseFunction.js';
import { FirebaseConfig } from './FirebaseConfig.js';

/**
 * @typedef {Object} AuthenticationOptions
 * @property {string} [userId] - The ID of the user making the request. This is required only if the admin auth service is used.
 * @property {string} [serverAccessToken] - A server access token to use in place of a user id. This is used in certain CSI calls where a user is not authenticated.
 */

/**
 * Calls the cross-server identity action function on the central server (katapult-pro-shared-resources).
 * @param {import('firebase/compat').default.auth.Auth | import('firebase-admin/auth').Auth} auth - Either the client auth service or the admin auth service.
 * @param {string} action - The action to perform.
 * @param {object} data - The data that goes along with the action.
 * @param {AuthenticationOptions} [authenticationOptions] - Authentication options for the request
 * @returns {Promise<any>} A promise that resolves to the result of the action.
 */
export async function CallCrossServerIdentityAction(auth, action, data, authenticationOptions = { userId: '', serverAccessToken: '' }) {
  // Attempt to get a JWT for the user if we don't have a server access token
  let jwt = '';
  if (!authenticationOptions.serverAccessToken) {
    // Get a JSON Web Token for the user
    jwt = await getJWTForUser(auth, authenticationOptions.userId);
    if (!jwt) throw new Error('Failed to get a JSON Web Token (JWT) for the user.');
  }

  // Call the cross-server identity action function
  return CallOnRequestFirebaseFunction('crossServerIdentityAction', 'katapult-pro-shared-resources', {
    jwt: authenticationOptions.serverAccessToken || jwt,
    action,
    ...data
  });
}

/**
 * Retrieves a JSON Web Token (JWT) for a user. Returns an empty string if the JWT could not be retrieved.
 * @param {import('firebase/compat').default.auth.Auth | import('firebase-admin/auth').Auth} auth - Either the client auth service or the admin auth service.
 * @param {string} [userId] - The ID of the user to get the JWT for. This is required only if the admin auth service is used.
 * @returns {Promise<string>} - A promise that resolves to the JWT or an empty string.
 */
async function getJWTForUser(auth, userId = '') {
  // If the auth service is the client auth service
  if ('currentUser' in auth) {
    return (await auth.currentUser?.getIdToken(true)) ?? '';
  }
  // If the auth service is the admin auth service
  else if ('createCustomToken' in auth) {
    // First create a custom token for the user
    const customToken = await auth.createCustomToken(userId);
    // Then use the custom token to sign in and get an IdToken (JWT) for the user
    const firebaseConfig = FirebaseConfig(process?.env?.GCLOUD_PROJECT);
    const { initializeApp } = await import('firebase/app');
    const app = initializeApp(firebaseConfig);
    const { getAuth, signInWithCustomToken } = await import('firebase/auth');
    const authClient = getAuth(app);
    await signInWithCustomToken(authClient, customToken);
    return (await authClient.currentUser?.getIdToken(true)) ?? '';
  }
  return '';
}
