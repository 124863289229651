/**
 * Checks if a password meets system and server requirements for use.
 * @param {string} password - password to check
 * @param {Object} passwordRules - password rules configuration object
 * @param {number} passwordRules.minLength - minimum password length
 * @param {string[]} passwordRules.regex - array of regex patterns
 * @param {string} passwordRules.regexDesc - description of regex pattern requirements
 * @param {number} passwordRules.regexNum - number of regex patterns that must be matched
 * @param {number} passwordRules.minPasswordScore - minimum zxcvbn password score
 * @param {(password:string)=>{ score:number }} zxcvbn - zxcvbn function (https://github.com/trichards57/zxcvbn)
 * @returns {{ score:number, isValid:boolean, errorMessage:string }} password strength result
 */
export function checkPassword(password, passwordRules, zxcvbn) {
  const result = {
    score: 0,
    isValid: true,
    errorMessage: ''
  };

  if (passwordRules) {
    // Get regex patterns from firebase config.
    let regex = passwordRules.regex || [];
    if (!Array.isArray(regex)) regex = [regex];

    // Check password length.
    if (password.length < passwordRules.minLength) {
      result.isValid = false;
      result.errorMessage = `Must be at least ${passwordRules.minLength} characters.`;
    }
    // Check password adherence to regex patterns.
    else if (regex.length && regex.filter((x) => new RegExp(x).test(password)).length < (passwordRules.regexNum ?? regex.length)) {
      result.isValid = false;
      result.errorMessage = passwordRules.regexDesc;
    }
  }

  // Check if zxcvbn is available.
  if (typeof zxcvbn != 'function') throw new Error('zxcvbn was not provided to checkPassword function.');
  // Check password strength.
  if (result.isValid) {
    const zxcvbnResponse = zxcvbn(password);
    result.score = zxcvbnResponse.score;

    if (result.score < (passwordRules?.minPasswordScore ?? 3)) {
      result.isValid = false;
      result.errorMessage = `Password is too weak. Add another word or two.`;
    }
  }

  return result;
}
