import { SetUserName } from '../../modules/SetUserName.js';
import { html } from '@polymer/polymer/lib/utils/html-tag.js';
import { PolymerElement } from '@polymer/polymer/polymer-element.js';
import '../paper-table/paper-table.js';
import { _checkCrossServerIdentity } from '../../modules/ServerIdentity.js';
import { CallCrossServerIdentityAction } from '../../modules/CallCrossServerIdentityAction.js';
import { Path } from '../../modules/Path.js';
class KatapultContactInfo extends PolymerElement {
  static get template() {
    return html`
      <style>
        paper-table {
          margin: 8px 0;
        }
        paper-row > * {
          flex-grow: 1;
          margin: 0rem 1rem;
        }
      </style>
      <paper-table>
        <paper-row>
          <paper-input
            on-value-changed="contactInfoChanged"
            path="name/first"
            value="[[userRecord.name.first]]"
            label="First Name"
            readonly$="[[inputsDisabled]]"
          ></paper-input>
          <paper-input
            on-value-changed="contactInfoChanged"
            path="name/last"
            value="[[userRecord.name.last]]"
            label="Last Name"
            readonly$="[[inputsDisabled]]"
          ></paper-input>
        </paper-row>
      </paper-table>
      <paper-table>
        <paper-row>
          <paper-input
            on-value-changed="contactInfoChanged"
            path="address/street_line_1"
            value="[[userRecord.address.street_line_1]]"
            label="Address Line 1"
            readonly$="[[inputsDisabled]]"
          ></paper-input>
        </paper-row>
        <paper-row>
          <paper-input
            on-value-changed="contactInfoChanged"
            path="address/street_line_2"
            value="[[userRecord.address.street_line_2]]"
            label="Address Line 2"
            readonly$="[[inputsDisabled]]"
          ></paper-input>
        </paper-row>
        <paper-row>
          <paper-input
            on-value-changed="contactInfoChanged"
            path="address/city"
            style="flex-grow: 2;"
            value="[[userRecord.address.city]]"
            label="City"
            readonly$="[[inputsDisabled]]"
          ></paper-input>
          <paper-input
            on-value-changed="contactInfoChanged"
            path="address/state"
            value="[[userRecord.address.state]]"
            label="State"
            readonly$="[[inputsDisabled]]"
          ></paper-input>
          <paper-input
            on-value-changed="contactInfoChanged"
            path="address/zip"
            value="[[userRecord.address.zip]]"
            label="Zip"
            readonly$="[[inputsDisabled]]"
          ></paper-input>
        </paper-row>
      </paper-table>
      <paper-table>
        <paper-row>
          <paper-input
            on-value-changed="contactInfoChanged"
            path="phone_number"
            value="[[userRecord.phone_number]]"
            label="Phone"
            readonly$="[[inputsDisabled]]"
          ></paper-input>
        </paper-row>
      </paper-table>
    `;
  }
  static get is() {
    return 'katapult-contact-info';
  }
  static get properties() {
    return {
      contactInfoChanges: {
        type: Object,
        value: null,
        notify: true
      },
      uid: {
        observer: 'uidChanged'
      },
      inputsDisabled: {
        type: Boolean,
        value: false
      }
    };
  }
  static get observers() {
    return [];
  }
  ready() {
    super.ready();
  }
  contactInfoChanged(e) {
    let value = (e.detail.value || '').trim();
    this.contactInfoChanges = this.contactInfoChanges || {};
    this.contactInfoChanges[e.currentTarget.getAttribute('path')] = value;
  }
  async saveContactInfo() {
    if (this.contactInfoChanges == null) return;
    await this.writeContactInfoUpdate(this.contactInfoChanges);
    // TODO: Remove this flag check once CSI is fully implemented.
    // Check if cross-server identity is enabledf
    const crossServerIdentityIsEnabled = await _checkCrossServerIdentity(globalThis.FirebaseWorker.database());
    if (crossServerIdentityIsEnabled === true) {
      await CallCrossServerIdentityAction(globalThis.firebase.auth(), 'SYNC_DATA_FROM_SATELLITE_SERVER', {
        serverId: globalThis.config.appName,
        satelliteDataId: 'user_contact_info',
        userId: this.uid
      }).catch(async (error) => {
        const revertUpdate = {};
        for (const path in this.contactInfoChanges) {
          revertUpdate[path] = Path.get(this.userData, path, '/') ?? null;
        }
        await this.writeContactInfoUpdate(revertUpdate);
        throw Error(`Failed to sync user contact info data: ${error.message}`);
      });
    }
    this.contactInfoChanges = null;
  }
  async writeContactInfoUpdate(update) {
    await FirebaseWorker.ref(`users/${this.rootCompany}/${this.uid}`).update(update);
    if (Object.keys(update).some((key) => key.startsWith('name'))) {
      let name = {
        first: update['name/first'] || this.get('userRecord.name.first'),
        last: update['name/last'] || this.get('userRecord.name.last')
      };
      await SetUserName(this.uid, name, { rootCompany: this.rootCompany });
    }
  }
  uidChanged() {
    this.contactInfoChanges = null;
  }
}
window.customElements.define(KatapultContactInfo.is, KatapultContactInfo);
