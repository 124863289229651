import { html } from '@polymer/polymer/lib/utils/html-tag.js';
import { PolymerElement } from '@polymer/polymer/polymer-element.js';
import '@polymer/paper-progress/paper-progress.js';
import './katapult-contact-info.js';
import '../katapult-elements/katapult-button.js';
import { addFlagListener, removeFlagListener } from '../../modules/FeatureFlags.js';

class KatapultAccountSettings extends PolymerElement {
  static get template() {
    return html`
      <style>
        :host {
          height: 100%;
          width: 100%;
          flex-direction: column;
          display: flex;
          align-items: center;
          overflow-y: auto;
        }
        user-chip {
          margin: 8px;
          --user-chip-profile: {
            width: 192px;
            height: 192px;
            font-size: 88pt;
          };
        }
        .group {
          max-width: 100%;
          width: 40rem;
          margin-bottom: 2rem;
        }
        .group .header {
          display: flex;
          align-items: center;
          text-transform: uppercase;
          font-size: 18pt;
          color: var(--primary-text-color-faded);
          margin: 1rem;
        }
        .inputGroup {
          margin: 1rem 0;
        }
        .inputRow {
          display: flex;
        }
        .inputRow paper-input {
          flex-grow: 1;
          margin: 0rem 1rem;
        }
        .password-meter {
          padding: 0 16px;
          box-sizing: border-box;
        }
        .password-meter[value='0'],
        .password-meter[value='1'] {
          --paper-progress-active-color: var(--paper-red-500);
        }
        .password-meter[value='2'] {
          --paper-progress-active-color: var(--paper-orange-500);
        }
        .password-meter[value='3'] {
          --paper-progress-active-color: var(--paper-light-green-500);
        }
        .password-meter[value='4'] {
          --paper-progress-active-color: var(--paper-green-500);
        }
      </style>
      <user-chip style="margin-top: 4rem;" uid="[[uid]]" size="200" hide-name=""></user-chip>
      <div class="group">
        <div class="header">
          Contact Info
          <material-icon
            icon="help_outline"
            style="padding-left: 5px; cursor: default;"
            title="The information entered here should only be business contact information, and not personal."
          ></material-icon>
        </div>
        <katapult-contact-info
          id="contactInfo"
          root-company="[[rootCompany]]"
          uid="[[uid]]"
          user-record="[[userRecord]]"
          contact-info-changes="{{contactInfoChanges}}"
        ></katapult-contact-info>
        <iron-collapse opened="[[contactInfoChanges]]">
          <paper-table>
            <paper-row>
              <div style="flex-grow: 1;">[[contactInfoError]]</div>
              <katapult-button color="var(--secondary-color)" callback="saveContactInfo">Save Changes</katapult-button>
            </paper-row>
          </paper-table>
        </iron-collapse>
      </div>
      <div class="group">
        <div class="header">Change Password</div>
        <div class="inputGroup">
          <div class="inputRow">
            <paper-input
              on-value-changed="passwordInputChanged"
              value="{{password}}"
              label="Current Password"
              type="password"
              error-message="[[passwordError]]"
              invalid="[[passwordError]]"
            ></paper-input>
            <div style="flex: 1;">
              <paper-input
                id="newPasswordInput"
                on-value-changed="passwordInputChanged"
                value="{{newPassword}}"
                label="New Password"
                type="password"
                error-message="[[newPasswordError]]"
                invalid="[[newPasswordError]]"
              ></paper-input>
              <div>
                <paper-progress class="password-meter" value$="[[passwordStrength]]" min="-0.2" max="4" step="0.2"></paper-progress>
              </div>
            </div>
            <paper-input
              on-value-changed="passwordInputChanged"
              value="{{confirmNewPassword}}"
              label="Confirm New Password"
              type="password"
              error-message="[[confirmNewPasswordError]]"
              invalid="[[confirmNewPasswordError]]"
            ></paper-input>
          </div>
        </div>
        <iron-collapse opened="[[showChangePasswordButton]]">
          <div class="inputGroup">
            <div class="inputRow">
              <div style="flex-grow: 1;"></div>
              <katapult-button color="var(--secondary-color)" callback="changePassword">Change Password</katapult-button>
            </div>
          </div>
        </iron-collapse>
      </div>
      <div class="group">
        <div class="header">Options</div>
        <div class="inputGroup" style="margin: 1rem;">
          <slot name="account-options"></slot>
        </div>
      </div>
    `;
  }

  static get is() {
    return 'katapult-account-settings';
  }
  static get properties() {
    return {
      contactInfoChanges: {
        type: Object,
        value: null
      },
      rootCompany: {
        type: String
      },
      password: {
        type: String,
        notify: true
      },
      newPassword: {
        type: String,
        notify: true
      },
      confirmNewPassword: {
        type: String,
        notify: true
      },
      uid: {
        type: String,
        value: null
      },
      userRecord: {
        type: Object,
        value: null
      },
      tooltipIsInvisible: {
        type: Boolean,
        value: true
      }
    };
  }
  static get observers() {
    return ['updateInputsDisabled(rootCompany, limitAdminActionsToHomeServer)'];
  }
  constructor() {
    super();
    this.inputsDisabled = false;
    this.limitAdminActionsToHomeServer = false;
  }

  connectedCallback() {
    super.connectedCallback();

    this.limitAdminActionsToHomeServerFlagListener = addFlagListener('limit_admin_actions_to_home_server', (enabled) => {
      this.limitAdminActionsToHomeServer = enabled;
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    removeFlagListener('limit_admin_actions_to_home_server', this.limitAdminActionsToHomeServerFlagListener);
  }

  ready() {
    super.ready();
  }
  passwordInputChanged(e) {
    this.showChangePasswordButton = true;
    if (e.currentTarget.id == 'newPasswordInput') katapultAuth._checkPasswordStrength(e);
  }
  async changePassword() {
    await katapultAuth._changePassword(true);
    if (!this.passwordError && !this.newPasswordError && !this.confirmNewPasswordError) {
      this.password = ''; // Clear password since setting it from account settings.
      this.showChangePasswordButton = false;
    }
  }
  async saveContactInfo() {
    await this.$.contactInfo.saveContactInfo();
  }
}
window.customElements.define(KatapultAccountSettings.is, KatapultAccountSettings);
